import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "amfe-flexible/index.js";
import {
  Button,
  Notify,
  NavBar,
  Field,
  Form,
  Popup,
  Picker,
  Icon,
  Col,
  Row,
  Grid,
  GridItem,
  Collapse,
  CollapseItem,
  Loading,
  Overlay,
  Empty,
  Checkbox,
  CheckboxGroup,
  Radio,
  RadioGroup,
  Cell,
  CellGroup,
  Uploader,
  Toast,
  Tab,
  Tabs,
  ActionSheet,
  Calendar,
  DatetimePicker,
  Dialog,
  Pagination,
  Image as VanImage,
  Search,
  Divider,
  List,
} from "vant";
Vue.use(Button) // 按钮
  .use(Icon) // 图标
  .use(NavBar) // 导航栏
  .use(Form) // 表单
  .use(Field) // 输入框
  .use(Checkbox) // 复选框
  .use(CheckboxGroup) // 复选框组
  .use(Radio) // 单选框
  .use(RadioGroup) // 单选框组
  .use(Cell) // 单元格
  .use(CellGroup) // 单元格组
  .use(Popup) // 弹出层
  .use(Picker) // 选择器
  .use(Row) // 布局
  .use(Col) // 布局
  .use(Grid) // 宫格
  .use(GridItem) // 宫格
  .use(Collapse) // 宫格
  .use(CollapseItem) // 宫格
  .use(Loading) // 加载
  .use(VanImage) // 图片
  .use(Overlay) // 遮罩层
  .use(Empty) // 空数据
  .use(Uploader) // 文件上传
  .use(Tab) // 标签页
  .use(Tabs) // 标签页
  .use(Toast) // 轻提示
  .use(ActionSheet) // 动作面板
  .use(Calendar) // 日历
  .use(DatetimePicker) // 时间选择器
  .use(Pagination) // 分页
  .use(Dialog) // 弹出框
  .use(Notify) // 消息通知
  .use(Search) //搜索框
  .use(Divider) //分割线
  .use(List) //列表

Vue.config.productionTip = false;
import { getShareUrl } from "@/api/api.js";

router.beforeEach((to, from, next) => {
  if (to.path === '/') {
    window.location.href = 'introduce.html'
    return;
  }
  const token = window.sessionStorage.getItem("token");
  if (!token) {
    if (to.path == '/manager-system') {
      next({ path: '/' })
    } else if (to.path == '/followPerson') {
      next({ path: '/' })
    } else if (to.path == '/platform') {
      next({ path: '/' })
    } else {
      next()
    }
  } else {
    getShareUrl({ url: window.location.href }).then((res) => {
      wx.config({
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: res.data.appId, // 必填，公众号的唯一标识
        timestamp: res.data.timestamp, // 必填，生成签名的时间戳
        nonceStr: res.data.nonceStr, // 必填，生成签名的随机串
        signature: res.data.signature,// 必填，签名
        jsApiList: res.data.jsApiList // 必填，需要使用的JS接口列表
      });
      wx.ready(function () {
        // 在这里调用 API
        wx.hideMenuItems({
            menuList: ['menuItem:share:appMessage','menuItem:share:timeline','menuItem:share:qq','menuItem:share:weiboApp','menuItem:favorite','menuItem:share:facebook','menuItem:share:QZone'] // 要隐藏的菜单项，只能隐藏“传播类”和“保护类”按钮，所有menu项见附录3
        })
      });
    })
    next()
  }
})

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
