import Vue from 'vue';
import VueRouter from 'vue-router';
import managerRoutes from './modules/manager/';
import followPersonRoutes from './modules/followPerson/';
import platform from './modules/platform/';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Index',
    component: () => import('../views/index.vue'),
  },
  {
    path: '/userInfo',
    name: 'UserInfo',
    component: () => import('../views/userInfo/index.vue'),
  },
  // 注册
  {
    path: '/register',
    name: 'Register',
    component: () => import('../views/register/register.vue'),
  },
  // 注册成功页面
  {
    path: '/registerSuccess',
    name: 'RegisterSuccess',
    component: () => import('../views/register/registerSuccess.vue'),
  },
  // 管理员内部系统
  ...managerRoutes,
  // 跟进人员
  ...followPersonRoutes,
  // 平台
  ...platform,
  // 用户
];

const router = new VueRouter({
  // mode: 'history',
  // base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return {
      x: 0,
      y: 0
    }
  }
});

export default router;
