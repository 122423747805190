const managerRoutes = [
  {
    path: "/manager",
    redirect: "/manager-system",
    component: () => import("@/views/manager/"),
    children: [
      {
        path: "/manager-system",
        component: () => import("@/views/manager/system/index.vue"),
        meta: { name: "内部系统", isShow: false, isTitle: false },
      },
      {
        path: "/personnel",
        component: () => import("@/views/manager/personnel/index.vue"),
        meta: { name: "人员审核", isShow: true, isTitle: true },
      },
      {
        path: "/addCustomer",
        component: () => import("@/views/manager/addCustomer/index.vue"),
        meta: { name: "新增客户", isShow: true, isTitle: true },
      },
      {
        path: "/myClient",
        component: () => import("@/views/manager/myClient/index.vue"),
        meta: { name: "我的客户", isShow: true, isTitle: true ,prevLink:"/manager-system"},
      },
      {
        path: "/followUp",
        component: () => import("@/views/manager/followUp/index.vue"),
        meta: { name: "查询跟进", isShow: true, isTitle: true },
      },
      {
        path: "/appointStat",
        component: () => import("@/views/manager/appointStat/index.vue"),
        meta: { name: "预约统计", isShow: true, isTitle: true },
      },
      {
        path: "/followInfo",
        component: () => import("@/views/manager/myClient/followInfo.vue"),
        meta: { name: "跟进信息", isShow: true, isTitle: true },
      },
      {
        path: "/appointGarden",
        component: () => import("@/views/manager/myClient/appointGarden.vue"),
        meta: { name: "预约入园信息", isShow: true, isTitle: true },
      },
    ],
  },
  //   {
  //     path: '/orderDetails/:id',
  //     component: () => import('@/view/personalCenter/modules/orderDetails/'),
  //   },
];
export default managerRoutes;
