
const followPersonRoutes = [
  {
    path: "/platform",
    redirect: "/platform",
    component: () => import("@/views/platform/"),
    children: [
      {
        path: "/platform",
        component: () => import("@/views/platform/system/index.vue"),
        meta: { name: "内部系统", isShow: false, isTitle: false },
      },
      {
        path: "/adminAudit",
        component: () => import("@/views/platform/adminAudit/index.vue"),
        meta: { name: "管理员审核", isShow: true, isTitle: true },
      },
      {
        path: "/updateCustomer",
        component: () => import("@/views/platform/updateCustomer/index.vue"),
        meta: { name: "修改客户记录", isShow: true, isTitle: true },
      },
    ],
  },
];
export default followPersonRoutes;
