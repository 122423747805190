
const followPersonRoutes = [
  {
    path: "/followPerson",
    redirect: "/followPerson",
    component: () => import("@/views/followPerson/"),
    children: [
      {
        path: "/followPerson",
        component: () => import("@/views/followPerson/system/index.vue"),
        meta: { name: "内部系统", isShow: false, isTitle: false },
      },
      {
        path: "/myFollow",
        component: () => import("@/views/followPerson/myFollow/index.vue"),
        meta: { name: "我的跟进", isShow: true, isTitle: true, prevLink:"/followPerson" },
      },
    ],
  },
];
export default followPersonRoutes;
