import qs from 'qs'
import request from '@/utils/request';

/**
 * 获取门店和职位信息 及其他下拉列表选择
 */
export const getSelectOptionsList = params => {
	return request({
		url: '/users/index',
		method: 'get',
		params: qs.parse(params)
	});
};

/**
 * 发送短信验证码
 */
export const getSendCode = params => {
	return request({
		url: '/verificationcodes',
		method: 'post',
		data: qs.parse(params)
	});
};

/**
 * 图片验证码
 */
export const getPictrueCode = params => {
	return request({
		url: '/captchas',
		method: 'post',
		data: qs.parse(params)
	});
};

/**
 * 管理员和跟进人员注册
 */
export const register = params => {
	return request({
		url: '/users',
		method: 'post',
		data: qs.parse(params)
	});
};

/**
 * 管理员和跟进人员登录
 */
export const login = params => {
	return request({
		url: '/login',
		method: 'post',
		data: qs.parse(params)
	});
};

/**
 * 新增客户
 */
export const addCustomer = params => {
	return request({
		url: '/orders',
		method: 'post',
		data: qs.parse(params)
	});
};

/**
 * 人员审批列表
 */
export const getPersonnelList = params => {
	return request({
		url: '/users',
		method: 'get',
		params
	});
};

/**
 * 人员审批通过、驳回、注销
 */
export const editAudit = params => {
	return request({
		url: '/users/edit',
		method: 'put',
		params: qs.parse(params)
	});
};

/**
 * 我的客户列表
 */
export const getMyClientList = params => {
	return request({
		url: '/orders',
		method: 'get',
		params
	});
};

/**
 * 查询当前管理员和跟进人员列表
 */
export const getFollowList = params => {
	return request({
		url: '/ordervisitlogs/create',
		method: 'get',
		params
	});
};

/**
 * 客户跟进信息
 */
export const contactlogs = params => {
	return request({
		url: '/contactlogs',
		method: 'post',
		data: qs.parse(params)
	});
};

/**
 * 修改客户信息
 */
export const updateCustomer = params => {
	return request({
		url: '/seekhelps',
		method: 'post',
		data: qs.parse(params)
	});
};

/**
 * 跟换跟进人
 */
export const changeFollowPerson = params => {
	return request({
		url: '/orders/orderedit',
		method: 'put',
		params: qs.parse(params)
	});
};

/**
 * 预约入园信息
 */
export const orderconfirms = params => {
	return request({
		url: '/orderconfirms',
		method: 'post',
		data: qs.parse(params)
	});
};

/**
 * 客户签单
 */
export const orderfinishlogs = params => {
	return request({
		url: '/orderfinishlogs',
		method: 'post',
		data: qs.parse(params)
	});
};

/**
 * 客户参观记录
 */
export const ordervisitlogs = params => {
	return request({
		url: '/ordervisitlogs',
		method: 'post',
		data: qs.parse(params)
	});
};

/**
 * 客户跟进记录
 */
export const getFllowUpRecords = params => {
	return request({
		url: '/contactlogs/create',
		method: 'get',
		params: qs.parse(params)
	});
};

/**
 * 客户信息注销
 */
export const deleteCustomer = params => {
	return request({
		url: '/orderdeletes',
		method: 'post',
		data: qs.parse(params)
	});
};

/**
 * 查询跟进列表
 */
export const getFowllowUpList = params => {
	return request({
		url: '/contactlogs',
		method: 'get',
		params: qs.parse(params)
	});
};

/**
 * 平台方管理员审核列表
 */
export const getAdminlList = params => {
	return request({
		url: '/admins',
		method: 'get',
		params: qs.parse(params)
	});
};

/**
 * 平台方管理员审核通过 驳回注销
 */
export const editAuditAdmin = params => {
	return request({
		url: '/admins',
		method: 'put',
		params: qs.parse(params)
	});
};

/**
 * 平台方修改客户记录
 */
export const getCustomerInfo = params => {
	return request({
		url: '/seekhelps',
		method: 'get',
		params: qs.parse(params)
	});
};

/**
 * 平台方修改客户记录
 */
export const updateCustomerInfo = params => {
	return request({
		url: '/orders/edit',
		method: 'put',
		params: qs.parse(params)
	});
};

/**
 * 平台方修改驳回
 */
export const updateCustomerRejected = params => {
	return request({
		url: '/seekhelps/edit',
		method: 'put',
		params: qs.parse(params)
	});
};

/**
 * 管理员预约统计
 */
export const appointStatistical = params => {
	return request({
		url: '/ordervisitlogs',
		method: 'get',
		params
	});
};

/**
 * 获取管理员和跟进人员列表
 */
export const getAdminAndFollowList = params => {
	return request({
		url: '/ordervisitlogs/visit',
		method: 'get',
		params
	});
};

/**
 * 跟进查询预约记录
 */
export const getReserRecordList = params => {
	return request({
		url: '/orderconfirms/list',
		method: 'get',
		params
	});
};

/**
 * 一键提醒跟进人
 */
export const remindFollow = params => {
	return request({
		url: '/wx/templates',
		method: 'post',
		data: qs.parse(params)
	});
};

/**
 * 公众号分享
 */
export const getShareUrl = params => {
	return request({
		url: '/wx/jweixins',
		method: 'post',
		data: qs.parse(params)
	});
};