import axios from 'axios';
// import store from '@/store';
// import { Notify } from 'vant';
import { Toast } from 'vant';
const Axios = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 50000, // 超时设置s
  withCredentials: true, // 是否允许带cookie这些
});

// 响应拦截器
Axios.interceptors.response.use(
  (response) => {
    const res = response.data;
    if (response.headers.authorization) {
      window.sessionStorage.setItem('token', response.headers.authorization);
    }
    return res;
  },
  (error) => {
    if (error && error.response) {
      switch (error.response.status) {
        case 500:
          Toast.fail(`网络连接超时`);
          break;
        default:
          Toast.fail(error.response.data.message);
      }
    }
    return Promise.reject(error)
  }
);

Axios.interceptors.request.use(
  (config) => {

    // // 每次发送请求之前判断vuex中是否存在token
    // // 如果存在，则统一在http请求的header都加上token，这样后台根据token判断你的登录情况
    // // 即使本地存在token，也有可能token是过期的，所以在响应拦截器中要对返回状态进行判断

    config.headers['Content-Type'] = 'application/x-www-form-urlencoded';
    config.headers['Content-Type'] = 'application/json';
    config.headers['X-Requested-With'] = 'XMLHttpRequest';
    // if (store.getters.token) {
    //   config.headers['Authorization'] = sessionStorage.getItem('token');
    // }
    const token = window.sessionStorage.getItem("token");
    if (token) {
      config.headers['Authorization'] = token;
    }
    return config;
  },
  (error) => {
    return Promise.error(error);
  }
);

export default Axios;
